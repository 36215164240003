

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from './webgiving-data-service';
export interface WebGivingLogInfoRequest {
	Type: WebGivingLogInfoType;
	Translations: string | null;
	ApplePaySDKInfo: ApplePaySDKLogInfo | null;
	SourcePageName: string | null;
}
export interface ApplePaySDKLogInfo {
	CanMakePaymentsWithActiveCard: boolean;
	CanMakePayments: boolean;
}
export enum WebGivingLogInfoType {
	Unknown = 0, StockGiving = 1, StockEngagement = 2, CryptoGiving = 3, CryptoEngagement = 4, MissingTranslations = 5, ApplePaySDK = 6, ApplePaySheetOpening = 7, ApplePaySheetClosed = 8, ApplePaySheetClosedWithError = 9,
}
export interface ApplePayValidateMerchantRequest {
	Uri: string;
}
export interface ApplePayValidateMerchantResponse {
	Success: boolean;
	Result: string;
}
export interface UpdateFastModeEnabledRequest {
	FastModeEnabled: boolean;
	GiftAmount: number;
}
export interface UpdateFastModeEnabledErrorResponse {
	Success: false;
	ErrorCode: UpdateFastModeEnabledErrorCode;
}
export enum UpdateFastModeEnabledErrorCode {
	Unknown = 0, SessionEnded = 1,
}
export interface UpdateFastModeEnabledResponse {
	Success: true;
	FastModeLimit: number;
}
export interface GetAppContentCardResponse {
	Data: AppContentCardModel[];
}
export interface AppContentCardModel {
	Id: string;
	CardType: AppContentCardType;
	ImageUrl: string;
	Title: string;
	Subtitle: string;
	Summary: string;
	TabName: string;
	IsFeatured: boolean;
	EventStartTime?: Date | null;
	EventEndTime?: Date | null;
	EventAllDay: boolean;
}
export enum AppContentCardType {
	Unknown = 0, Default = 1, Video = 2, Audio = 3, Event = 4,
}
export interface RecurringSuggestionModel {
	FundKey: string;
	SignedPayerId: string;
	Frequency?: FrequencyCode | null;
	RecurrenceDate?: Date | null;
	Amount?: number | null;
}
export enum FrequencyCode {
	Other = 0, Weekly = 1, Fortnightly = 2, Monthly = 3, FirstAndFifteenth = 4, OneMinute = 5, FiveMinutes = 6, FourHours = 7, Yearly = 8, SemiYearly = 9, Quarterly = 10
}
export interface RecurringSuggestionQuery {
	FundKey: string;
	SignedPayerId: string;
	Amount: number;
}
export interface RecurringSuggestionQueryResult {
	Frequency?: FrequencyCode | null;
	ShowSuggestion: boolean;
	StartOnDate?: Date | null;
	FundKey: string;
	Amount: number;
	Currency: Currency;
}
export enum Currency {
	NZD = 0, AUD = 1, USD = 2, CAD = 3, Unspecified = -1,
}
export interface PageStatsRequest {
	PageName: string;
	PaymentIdentifier: string;
	BrandingElements: string[] | null;
	AdditionalData: { [key: string]: string } | null;
	Timestamp: Date;
}
export interface ForgetMeResponse {
	NewCSRFToken: string;
}
export interface ConfirmPaymentConfirmCodeRequest {
	Code: string;
	PaymentToken: string;
	PaymentTokens: string[] | null;
	RequestToken: string;
	RememberMe: boolean;
	PayerEnabledAnniversaryReminder: boolean;
	ShouldSendWelcomeSms: boolean;
	RecurringSuggestionId?: number | null;
	EmailAppContentCardModel: AppContentCardModel | null;
	DisplayedInWebGivingAppContentCardType?: AppContentCardType | null;
	Language: Language;
	InitialUrlParameters: string | null;
	ApplePayInfo: ApplePayInfo | null;
}
export interface ApplePayInfo {
	ApplePayPaymentToken: ApplePayPaymentToken;
	ApplePayBillingContact: ApplePayBillingContact;
}
export interface ApplePayBillingContact {
	AddressLine1: string;
	AddressLine2: string;
	City: string;
	State: string;
	Zip: string;
	Country: string;
}
export interface ApplePayPaymentToken {
	PaymentData: string;
	PaymentMethod: ApplePayPaymentMethod;
	TransactionIdentifier: string;
}
export interface ApplePayPaymentMethod {
	DisplayName: string;
	Network: string;
	Type: ApplePayPaymentMethodType;
	PaymentPass: ApplePayPaymentPass;
}
export interface ApplePayPaymentPass {
	PrimaryAccountIdentifier: string;
	PrimaryAccountNumberSuffix: string;
	DeviceAccountIdentifier: string | null;
	DeviceAccountNumberSuffix: string | null;
	ActivationState: ApplePayPaymentPassActivationState;
}
export enum ApplePayPaymentPassActivationState {
	Activated = 0, RequiresActivation = 1, Activating = 2, Suspended = 3, Deactivated = 4,
}
export enum ApplePayPaymentMethodType {
	Debit = 0, Credit = 1, Prepaid = 2, Store = 3,
}
export interface Language {
	Name: string;
	TwoLetterIsoCode: string;
}
export interface PollResultRequest {
	PaymentToken: string;
}
export interface ResendConfirmPaymentCodeRequest {
	PaymentToken: string;
	PaymentTokens: string[] | null;
	UseVoiceCall?: boolean | null;
	Language: Language;
}
export interface ConfirmPaymentRequest {
	LineItems: SplitLineItem[];
	Amount: number;
	AmountWithFees?: number | null;
	PayerElectedToCoverFees: boolean;
	PayerEnabledAnniversaryReminder: boolean;
	PaymentMethod: string;
	ListingId: number;
	LockVersion: number;
	Refs: CustomFieldEditModel[];
	IsRecurring: boolean;
	IsOneTimeFutureGift: boolean;
	GiftDate?: Date | null;
	RecurringDetails: ConfirmPaymentRecurringDetails | null;
	UrlParameters: string | null;
	EmailAppContentCardModel: AppContentCardModel | null;
	DisplayedInWebGivingAppContentCardType?: AppContentCardType | null;
	PayerPrefersVoiceCall: boolean;
	ShouldSendWelcomeSMS: boolean;
	Notes: string;
	AdditionalData: string;
	ExternalReference: string;
	AdditionalHiddenFundKey: string;
	IncludeHiddenFundField: boolean;
	TermsAndConditionsVersion: string;
	InitialUrlParameters: string | null;
	RecurringSuggestionId?: number | null;
	Language: Language;
	ApplePayInfo: ApplePayInfo | null;
}
export interface ConfirmPaymentRecurringDetails {
	Frequency: FrequencyCode;
	StartDate: Date;
	EndType: EndType;
	EndDate?: Date | null;
	EndOccurrences?: number | null;
}
export enum EndType {
	Never = 0, Date = 1, Occurrences = 2,
}
export interface CustomFieldEditModel {
	Key: string;
	Type: CustomFieldType;
	Value: string;
}
export enum CustomFieldType {
	Unknown = 0, Text = 1, Number = 2, DropDown = 3, Fund = 4,
}
export interface SplitLineItem {
	Amount: number;
	AmountWithFees?: number | null;
	FundKey: string;
}
export interface ConfirmPaymentResponse {
	ConfirmPaymentLineItemResults: ConfirmPaymentLineItemResult[] | null;
	Status: WebGivingPaymentResultStatus;
	ResultDescription: string;
	PaymentToken: string;
	RequestToken: string;
	MobileNumber: WebGivingMobileNumber;
	ConfirmedMobileToken: string;
	SuccessThankYouAnimationType: ThankYouAnimationType;
	AppConnectData: string | null;
	UpdatedMerchantDetails: MerchantConflictResponse | null;
	UpdatedPersonPaymentMethods: WebGivingPaymentMethodModel[] | null;
	SuccessThankYouMessage: string | null;
	ReceiptHasBeenSent: boolean;
	WelcomeSMSHasBeenSent: boolean;
	FastModeAvailable: boolean;
	FastModeCurrentlyEnabled: boolean;
	FastModeDailyLimit?: number | null;
	ConfirmationCodeError: false;
	ConfirmationReason: CheckCodelessPaymentResult;
	RedirectUrl: string;
}
export enum CheckCodelessPaymentResult {
	Unknown = 0, Success = 1, RecurringPayment = 2, CodelessPaymentDisabled = 3, PaymentOverDailyLimit = 4, PaymentsTotalOverDailyLimit = 5, CodelessPaymentTemporarilyDisabled = 6, CodelessPaymentExpired = 7
}
export interface WebGivingPaymentMethodModel {
	Id: string;
	BrandSvgLogo: string;
	BrandDisplayName: string;
	IsPreferred: boolean;
	IsUnavailable: boolean;
	PaymentMethodType: PaymentMethodType;
	Nickname: string;
	CardExpiryMonth: number;
	CardExpiryYear: number;
	DisplayReference: string;
}
export enum PaymentMethodType {
	Unknown = 0, CreditCard = 1, NzBankAccount = 2, ACH = 3, Cash = 4, RecordedCheck = 5, AchCheck = 6, ProfitStarsScannedCheck = 7, RecordedCreditCard = 8, RecordedACH = 9, ImportedUnspecified = 10, ImportedUnsupported = 11,
	ApplePay = 12,
}
export interface WebGivingMobileNumber {
	NormalizedInternationalNumber: string;
	NationalNumber: string;
}
export enum WebGivingPaymentResultStatus {
	Success = 0, Fail = 1, RequiresConfirmation = 2, Poll = 3, Processing = 4, TimedOut = 5, CardFail = 6, SuspectedAccidentalDuplicate = 7, InvalidAmount = 8, MaximumYearExceeded = 9, InvalidReferences = 10,
	PaymentMethodNotSupported = 11, Delayed = 12, InvalidSignedConfigWithRedirectUrl = 13,
}
export interface ConfirmPaymentLineItemResult {
	Status: WebGivingPaymentResultStatus;
	ResultDescription: string;
	PaymentToken: string;
	Amount: number;
	FundKey: string;
}
export interface ConfirmACHAccountRequest {
	RoutingNumber: string;
	AccountNumber: string;
	AccountType: AchAccountType;
}
export enum AchAccountType {
	Checking = 1, Savings = 2,
}
export interface AddACHPaymentMethodRequest {
	RoutingNumber: string;
}
export interface AddACHPaymentMethodErrorResponse {
	Success: false;
	InvalidRoutingNumber: boolean;
}
export interface AddACHPaymentMethodResponse {
	Success: true;
	BankName: string;
}
export interface AddCreditCardPaymentMethodRequest {
	CardNumber: string;
	CardExpirationMonth: string;
	CardExpirationYear: string;
	CardSecurity: string;
	ZipCode: string;
}
export interface AddPaymentMethodErrorResponse {
	Success: false;
	ResultCode: WebGivingAddPaymentMethodResultCode;
	ErrorMessageLines: string[];
	Reason: string;
}
export enum WebGivingAddPaymentMethodResultCode {
	Unknown = 0, Succeeded = 1, UserCorrectableError = 2, CardTokenizationUnavailable = 3,
}
export interface AddPaymentMethodResponse {
	Success: true;
	ResultCode: WebGivingAddPaymentMethodResultCode;
	PaymentMethods: WebGivingPaymentMethodModel[];
	LastAddedId: number;
}
export interface CreateAccountRequest {
	FirstName: string;
	LastName: string;
	Email: string;
	RememberMe: boolean;
	Language: Language;
}
export interface CreateAccountResponse {
	Person: WebGivingPersonModel;
	RegistrationConfirmationModel: RegistrationConfirmationModel;
}
export interface RegistrationConfirmationModel {
	Name: string;
	WebsiteConfirmationLink: string;
	ReferMerchantApp: boolean;
	MerchantName: string;
	PaymentNoun: string;
	PaymentNounPlural: string;
	Title: string;
	Flavour: string;
	PostTitleMessage: string;
	ListUnsubscribeHeader: string;
	MerchantDisplayAddressLines: string[];
	AuthCode: string;
	Subject: string;
}
export interface WebGivingPersonModel {
	Nickname: string;
	PaymentMethods: WebGivingPaymentMethodModel[];
	MobileNumber: WebGivingMobileNumber;
	PostalCodeCommonName: string;
	PostalCodeRegex: string;
	NewCSRFToken: string;
	TimeZoneInfo: TimeZoneInfo;
	TimeZoneId: string;
	CultureCode: string;
	EmailAddress: string;
	SignedPersonId: string;
	TermsAndConditionsVersion: string;
	HasVerifiedEmailAddress: boolean;
}
export interface TimeZoneInfo {
	Id: string;
	DisplayName: string;
	StandardName: string;
	DaylightName: string;
	BaseUtcOffset: string;
	SupportsDaylightSavingTime: boolean;
}
export interface ResendConfirmationCodeRequest {
	NormalizedInternationalNumber: string;
	UseVoiceCall?: boolean | null;
	Language: Language;
}
export interface EnterMobileNumberConfirmCodeRequest {
	Code: string;
	RequestToken: string;
	RememberMe: boolean;
	NormalizedInternationalNumber: string;
}
export interface ConfirmationCodeErrorResponse {
	ConfirmationCodeError: true;
	ConfirmationCodeInvalid: boolean;
	Reason: string;
}
export interface EnterMobileNumberConfirmationResponse {
	Person: WebGivingPersonModel | null;
	ConfirmedMobileNumber: WebGivingMobileNumber;
	ConfirmationCodeError: false;
}
export interface EnterMobileNumberRequest {
	MobileNumber: string;
	Country: string;
	UseVoiceCall?: boolean | null;
	Language: Language;
}
export interface EnterMobileNumberErrorResponse {
	Success: false;
	Reason: string;
}
export interface EnterMobileNumberResponse {
	MobileNumber: WebGivingMobileNumber;
	RequestToken: string;
	FastModeActive: boolean;
	Success: true;
}
export enum ThankYouAnimationType {
	None = 0, Hearts = 1, Confetti = 2,
}
export enum ResetPageReason {
	Unknown = 0, PersonNotFound = 1, PaymentNotFound = 2, DuplicateMobileNumber = 3, AdminAccountLocked = 4, InvalidSignedConfig = 5, CookiesDisabled = 6,
}
export interface MerchantConflictResponse {
	MerchantInfo: WebGivingMerchant;
	Theme: Theme;
}
export interface Theme {
	PrimaryColor: string;
	SecondaryColor: string;
	Nonce: string;
	MerchantLogoUrl: string;
	ReturnUrl: string;
	ReturnLabel: string;
}
export interface WebGivingMerchant {
	Handle: string;
	TradingName: string;
	LegalName: string;
	MerchantId: number;
	MerchantVersion: number;
	IsHppEnabled: boolean;
	TermsAndConditions: string;
	PrivacyPolicy: string;
	Address: string;
	Country: Country;
	SupportsAch: boolean;
	SupportsPayerCoversFees: boolean;
	PayerCoversFeesDefault: boolean;
	TransactionFeeRate: number;
	PercentageOfPaymentAddedToCoverFees: number;
	SupportedCardBrandKeys: string[];
	CardPreference?: MerchantCardPreference | null;
	CustomFields: ICustomFieldViewModel[];
	PaymentLabel: PaymentLabel;
	PaymentMin: number;
	PaymentMax: number;
	AchMax?: number | null;
	LogoUrl: string;
	PaymentMethodPreference: PaymentMethodType;
	PayersMustBeVerified: boolean;
	PayerSpecifiedRecurringEndDateEnabled: boolean;
	WebsiteUrl: string;
	Currency: Currency;
	MobileAppName: string;
	GetAppUrl: string;
	HasCustomApp: boolean;
	WebGivingUrl: string;
	HasCustomLogo: boolean;
	BrandingEnabled: boolean;
	RecurringByDefault: boolean;
	HasCustomTermsOrPrivacy: boolean;
	OneTimeFutureGiftEnabled: boolean;
	IsEntitledToLanguageSelector: boolean;
	HasYearlyRecurringGivingEnabled: boolean;
	HasSemiYearlyRecurringGivingEnabled: boolean;
	HasQuarterlyRecurringGivingEnabled: boolean;
	MerchantCryptoLink: string;
	MerchantStockGivingLink: string;
	SupportsApplePay: boolean;
	SupportsApplePayRecurring: boolean;
	ApplePayRecurringEnabledInSplitGiving: boolean;
	SplitGivingEnabled: boolean;
	SupportsBackdropImages: boolean;
	ApplePayMerchantName: string;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export enum MerchantCardPreference {
	AnyCard = 0, DebitPreferred = 1, DebitOnly = 2,
}
export enum Country {
	NZ = 0, AU = 1, US = 2, AF = 4, AL = 8, AQ = 10, DZ = 12, AS = 16, AD = 20, AO = 24, AG = 28, AZ = 31, AR = 32, AT = 40, BS = 44, BH = 48, BD = 50, AM = 51, BB = 52, BE = 56, BM = 60, BT = 64, BO = 68,
	BA = 70, BW = 72, BV = 74, BR = 76, BZ = 84, IO = 86, SB = 90, VG = 92, BN = 96, BG = 100, MM = 104, BI = 108, BY = 112, KH = 116, CM = 120, CA = 124, CV = 132, KY = 136, CF = 140, LK = 144, TD = 148, CL = 152,
	CN = 156, TW = 158, CX = 162, CC = 166, CO = 170, KM = 174, YT = 175, CG = 178, CD = 180, CK = 184, CR = 188, HR = 191, CU = 192, CY = 196, CZ = 203, BJ = 204, DK = 208, DM = 212, DO = 214, EC = 218, SV = 222,
	GQ = 226, ET = 231, ER = 232, EE = 233, FO = 234, FK = 238, GS = 239, FJ = 242, FI = 246, AX = 248, FR = 250, GF = 254, PF = 258, TF = 260, DJ = 262, GA = 266, GE = 268, GM = 270, PS = 275, DE = 276, GH = 288,
	GI = 292, KI = 296, GR = 300, GL = 304, GD = 308, GP = 312, GU = 316, GT = 320, GN = 324, GY = 328, HT = 332, HM = 334, VA = 336, HN = 340, HK = 344, HU = 348, IS = 352, IN = 356, ID = 360, IR = 364, IQ = 368,
	IE = 372, IL = 376, IT = 380, CI = 384, JM = 388, JP = 392, KZ = 398, JO = 400, KE = 404, KP = 408, KR = 410, KW = 414, KG = 417, LA = 418, LB = 422, LS = 426, LV = 428, LR = 430, LY = 434, LI = 438, LT = 440,
	LU = 442, MO = 446, MG = 450, MW = 454, MY = 458, MV = 462, ML = 466, MT = 470, MQ = 474, MR = 478, MU = 480, MX = 484, MC = 492, MN = 496, MD = 498, ME = 499, MS = 500, MA = 504, MZ = 508, OM = 512, NA = 516,
	NR = 520, NP = 524, NL = 528, CW = 531, AW = 533, SX = 534, BQ = 535, NC = 540, VU = 548, NI = 558, NE = 562, NG = 566, NU = 570, NF = 574, NO = 578, MP = 580, UM = 581, FM = 583, MH = 584, PW = 585, PK = 586,
	PA = 591, PG = 598, PY = 600, PE = 604, PH = 608, PN = 612, PL = 616, PT = 620, GW = 624, TL = 626, PR = 630, QA = 634, RE = 638, RO = 642, RU = 643, RW = 646, BL = 652, SH = 654, KN = 659, AI = 660, LC = 662,
	MF = 663, PM = 666, VC = 670, SM = 674, ST = 678, SA = 682, SN = 686, RS = 688, SC = 690, SL = 694, SG = 702, SK = 703, VN = 704, SI = 705, SO = 706, ZA = 710, ZW = 716, ES = 724, SS = 728, SD = 729, EH = 732,
	SR = 740, SJ = 744, SZ = 748, SE = 752, CH = 756, SY = 760, TJ = 762, TH = 764, TG = 768, TK = 772, TO = 776, TT = 780, AE = 784, TN = 788, TR = 792, TM = 795, TC = 796, TV = 798, UG = 800, UA = 804, MK = 807,
	EG = 818, GB = 826, GG = 831, JE = 832, IM = 833, TZ = 834, VI = 850, BF = 854, UY = 858, UZ = 860, VE = 862, WF = 876, WS = 882, YE = 887, ZM = 894, XK = 999, __ = -2, Unsupported = -1,
}
export interface WebGivingViewModel {
	Theme: Theme;
	BrandingPackage: BrandingPackage | null;
	MerchantInfo: WebGivingMerchant;
	Person: WebGivingPersonModel | null;
	UIConfiguration: WebGivingUIConfiguration;
	ConfirmedMobileNumber: WebGivingMobileNumber | null;
	PreconfiguredPaymentDetails: WebGivingPreconfiguredPayment | null;
	GoogleAnalyticsTrackerId: string;
	SessionCorrelationId: string;
	PushpayInfo: WebGivingPushpayInfo;
	ClientIsMobilePhone: boolean;
	InitialQueryString: string | null;
	HideAppCallToAction: boolean;
	ConfettiAnimationType: ThankYouAnimationType;
	EvidentlyConfig: EvidentlyConfig;
	ApplePayMerchantIdentifier: string;
	DonorPortalBaseUrl: string;
}
export interface EvidentlyConfig {
	CognitoIdentityPoolId: string;
	CognitoRole: string;
	Region: string;
	Endpoint: string;
	Project: string;
}
export interface WebGivingPushpayInfo {
	TermsAndConditionsVersion: string;
	TermsAndConditionsUrl: string;
	PrivacyStatementUrl: string;
	TermsAndConditions: string;
	PrivacyStatement: string;
	HelpCenterLink: string;
	ManageAccountUrl: string;
	ResetPasswordUrl: string;
	UpdateProfileUrl: string;
	DonorPortalUpdateEmailUrl: string;
}
export interface WebGivingPreconfiguredPayment {
	Amount?: number | null;
	AmountIsLocked?: boolean | null;
	FirstName: string;
	LastName: string;
	EmailAddress: string;
	MobileNumber: string;
	HasRecurringConfiguration: boolean;
	RecurringEndType?: EndType | null;
	RecurringFrequency?: FrequencyCode | null;
	RecurringOccurrences?: number | null;
	RecurringEndDate?: Date | null;
	RecurringStartDate?: Date | null;
	GiftDate?: Date | null;
	CustomFields: CustomFieldEditModel[];
	UrlParameters: string | null;
	RedirectUrl: string;
	RecurringSuggestionEnabled: boolean;
	ExternalReference: string;
	FailedToResolveFundId: boolean;
	InvalidPreConfiguredLink: boolean;
	Notes: string;
	AdditionalData: string;
	RecurringSelectorIsVisible?: boolean | null;
	FundVisibility?: FundVisibility | null;
	AdditionalHiddenFundKey: string;
	IncludeHiddenFundField: boolean;
}
export enum FundVisibility {
	Default = 0, Show = 1, Hide = 2, Lock = 3,
}
export interface WebGivingUIConfiguration {
	AccountCreationText: string;
	ThankYouText: string;
	HideAccount: boolean;
	HideACH: boolean;
	HideI18n: boolean;
	ReturnButtonUrl: string;
	ReturnButtonText: string;
	HideDonorPortalGivingLinks: boolean;
	HideKioskGivingLinks: boolean;
	PaymentScreenText: string;
	HideRecurring: boolean;
	SkipGivePage: boolean;
	AttemptGivePageSkip: boolean;
	SuccessBackButtonRedirectUrl: string;
	HideApplePay: boolean;
	HideSplitGiving: boolean;
}
export interface BrandingPackage {
	Version: string | null;
	HeroImageUrl: string | null;
	HeroImageKey: string | null;
	EnhancedLogoImageUrl: string | null;
	EnhancedLogoImageKey: string | null;
	FaviconImageUrl: string | null;
	FaviconImageKey: string | null;
	BackdropImageKey: string | null;
	BackdropImageUrl: string | null;
	BackdropMobileImageKey: string | null;
	BackdropMobileImageUrl: string | null;
	GivingTitle: string | null;
	PrimaryColor: string;
	SecondaryColor: string;
	RetrievedAt: Instant;
	ETag: string | null;
	BrandingMerchantId: number | null;
	BrandingLevel: BrandingLevel;
}
export enum BrandingLevel {
	Unknown = 0, Merchant = 1, Campus = 2, Organization = 3,
}
export interface Instant {
}
export interface CommonPaymentFieldsModel {
	ReferenceFieldValues: CustomFieldEditModel[];
	GivenOn?: Date | null;
	Notes: string;
}
export interface FundCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Fund;
	DefaultOptionValue: string;
	Options: FundCustomFieldOptionViewModel[];
	Order: number;
}
export interface FundCustomFieldOptionViewModel {
	Label: string;
	Value: string;
	ForListing: boolean;
}
export interface DropDownCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.DropDown;
	DefaultOptionValue: string;
	Options: DropDownCustomFieldOptionViewModel[];
	Order: number;
}
export interface DropDownCustomFieldOptionViewModel {
	Label: string;
	Value: string;
}
export interface NumberCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Number;
	MinLength: number;
	MaxLength: number;
	Order: number;
}
export interface TextCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Text;
	MinLength: number;
	MaxLength: number;
	Validation: TextFieldValidation;
	Order: number;
}
export enum TextFieldValidation {
	NoValidation = 0, LettersOnly = 1,
}
export type ICustomFieldViewModel = TextCustomFieldViewModel | NumberCustomFieldViewModel | DropDownCustomFieldViewModel | FundCustomFieldViewModel;
export const WebGivingApiConfig = {
	defaultBaseUrl: () => (<RegExpMatchArray>location.pathname.match(new RegExp('/g/.+', 'i')))[0],
	actions: {
		enterMobileNumber: <IApiAction<{model: EnterMobileNumberRequest}, EnterMobileNumberResponse | EnterMobileNumberErrorResponse>>{
			url: (data) => `/EnterMobileNumber`,
		},
		enterMobileNumberConfirmationCode: <IApiAction<{model: EnterMobileNumberConfirmCodeRequest}, EnterMobileNumberConfirmationResponse | ConfirmationCodeErrorResponse>>{
			url: (data) => `/EnterMobileNumberConfirmationCode`,
		},
		resendConfirmationCode: <IApiAction<{model: ResendConfirmationCodeRequest}, EnterMobileNumberResponse | EnterMobileNumberErrorResponse>>{
			url: (data) => `/ResendConfirmationCode`,
		},
		suppressRedirect: <IApiAction<{reason: string}>>{
			url: (data) => `/SuppressRedirect`,
		},
		createAccount: <IApiAction<{model: CreateAccountRequest}, CreateAccountResponse>>{
			url: (data) => `/CreateAccount`,
		},
		addCreditCardPaymentMethod: <IApiAction<{model: AddCreditCardPaymentMethodRequest}, AddPaymentMethodResponse | AddPaymentMethodErrorResponse>>{
			url: (data) => `/AddCreditCardPaymentMethod`,
		},
		addACHPaymentMethod: <IApiAction<{addACHPaymentMethodRequest: AddACHPaymentMethodRequest}, AddACHPaymentMethodResponse | AddACHPaymentMethodErrorResponse>>{
			url: (data) => `/AddACHPaymentMethod`,
		},
		confirmACHAccount: <IApiAction<{model: ConfirmACHAccountRequest}, AddPaymentMethodResponse | AddPaymentMethodErrorResponse>>{
			url: (data) => `/ConfirmACHAccount`,
		},
		confirmPayment: <IApiAction<{model: ConfirmPaymentRequest}, ConfirmPaymentResponse>>{
			url: (data) => `/ConfirmPayment`,
		},
		resendConfirmPaymentCode: <IApiAction<{model: ResendConfirmPaymentCodeRequest}, ConfirmPaymentResponse>>{
			url: (data) => `/ResendConfirmPaymentCode`,
		},
		pollResult: <IApiAction<{model: PollResultRequest}, ConfirmPaymentResponse>>{
			url: (data) => `/PollResult`,
		},
		verifyPaymentConfirmationCode: <IApiAction<{model: ConfirmPaymentConfirmCodeRequest}, ConfirmPaymentResponse | ConfirmationCodeErrorResponse>>{
			url: (data) => `/VerifyPaymentConfirmationCode`,
		},
		forgetMe: <IApiAction<{}, ForgetMeResponse>>{
			url: (data) => `/ForgetMe`,
		},
		recordPageStats: <IApiAction<{model: PageStatsRequest}>>{
			url: (data) => `/RecordPageStats`,
		},
		logMissingTranslations: <IApiAction<{request: string}>>{
			url: (data) => `/LogMissingTranslations`,
		},
		getRecurringSuggestion: <IApiAction<{query: RecurringSuggestionQuery}, RecurringSuggestionQueryResult>>{
			url: (data) => `/GetRecurringSuggestion`,
		},
		recordRecurringSuggestionView: <IApiAction<{model: RecurringSuggestionModel}, number>>{
			url: (data) => `/RecordRecurringSuggestionView`,
		},
		getAppContentCards: <IApiAction<{numCards: number}, GetAppContentCardResponse>>{
			url: (data) => `/GetAppContentCards`,
		},
		updateFastModeEnabled: <IApiAction<{request: UpdateFastModeEnabledRequest}, UpdateFastModeEnabledResponse | UpdateFastModeEnabledErrorResponse>>{
			url: (data) => `/UpdateFastModeEnabled`,
		},
		validateApplePayMerchant: <IApiAction<{request: ApplePayValidateMerchantRequest}, ApplePayValidateMerchantResponse>>{
			url: (data) => `/ValidateApplePayMerchant`,
		},
		logCryptoGiving: <IApiAction<{}>>{
			url: (data) => `/LogCryptoGiving`,
		},
		logCryptoEngagement: <IApiAction<{}>>{
			url: (data) => `/LogCryptoEngagement`,
		},
		logStockGiving: <IApiAction<{}>>{
			url: (data) => `/LogStockGiving`,
		},
		logStockEngagement: <IApiAction<{}>>{
			url: (data) => `/LogStockEngagement`,
		},
		logInfo: <IApiAction<{request: WebGivingLogInfoRequest}>>{
			url: (data) => `/LogInfo`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let CommonPaymentFieldsModel : { [name: string]: IPropertyMetadata } = {
		ReferenceFieldValues: {
			propertyName: "ReferenceFieldValues",
		},
		GivenOn: {
			propertyName: "GivenOn",
			validationRules: {
				date: {
					errorMessage: "The field GivenOn must be a date.",
				},
			},
		},
		Notes: {
			propertyName: "Notes",
			validationRules: {
				length: {
					errorMessage: "Please enter a note 255 characters or fewer",
					parameters: {
						max: 255,
					},
				},
			},
		},
	};
	export let ConfirmPaymentRecurringDetails : { [name: string]: IPropertyMetadata } = {
		Frequency: {
			propertyName: "Frequency",
			validationRules: {
				required: {
					errorMessage: "The Frequency field is required.",
				},
			},
		},
		StartDate: {
			propertyName: "StartDate",
			validationRules: {
				date: {
					errorMessage: "The field StartDate must be a date.",
				},
				required: {
					errorMessage: "The StartDate field is required.",
				},
			},
		},
		EndType: {
			propertyName: "EndType",
			validationRules: {
				required: {
					errorMessage: "The EndType field is required.",
				},
			},
		},
		EndDate: {
			propertyName: "EndDate",
			validationRules: {
				date: {
					errorMessage: "The field EndDate must be a date.",
				},
			},
		},
		EndOccurrences: {
			propertyName: "EndOccurrences",
			validationRules: {
				number: {
					errorMessage: "The field EndOccurrences must be a number.",
				},
			},
		},
	};
	export let EnterMobileNumberRequest : { [name: string]: IPropertyMetadata } = {
		MobileNumber: {
			propertyName: "MobileNumber",
			displayName: "Mobile",
			placeholder: "e.g. 555 123 4567",
			validationRules: {
				length: {
					errorMessage: "This number is not valid",
					parameters: {
						max: 20,
						min: 5,
					},
				},
				required: {
					errorMessage: "Please enter mobile number",
				},
				skipsanitization: {},
			},
		},
		Country: {
			propertyName: "Country",
			validationRules: {
				length: {
					errorMessage: "The field Country must be a string with a maximum length of 2.",
					parameters: {
						max: 2,
					},
				},
				required: {
					errorMessage: "The Country field is required.",
				},
			},
		},
		UseVoiceCall: {
			propertyName: "UseVoiceCall",
		},
		Language: {
			propertyName: "Language",
		},
	};
	export let EnterMobileNumberConfirmCodeRequest : { [name: string]: IPropertyMetadata } = {
		Code: {
			propertyName: "Code",
			displayName: "Confirmation Code",
			placeholder: "e.g. 123456",
			validationRules: {
				length: {
					errorMessage: "Please enter a 6 digit security code",
					parameters: {
						max: 6,
						min: 6,
					},
				},
				required: {
					errorMessage: "Please enter a 6 digit security code",
				},
			},
		},
		RequestToken: {
			propertyName: "RequestToken",
			validationRules: {
				skipsanitization: {},
			},
		},
		RememberMe: {
			propertyName: "RememberMe",
			validationRules: {
				required: {
					errorMessage: "The RememberMe field is required.",
				},
			},
		},
		NormalizedInternationalNumber: {
			propertyName: "NormalizedInternationalNumber",
			validationRules: {
				length: {
					errorMessage: "The field NormalizedInternationalNumber must be a string with a minimum length of 9 and a maximum length of 20.",
					parameters: {
						max: 20,
						min: 9,
					},
				},
				skipsanitization: {},
			},
		},
	};
	export let ResendConfirmationCodeRequest : { [name: string]: IPropertyMetadata } = {
		NormalizedInternationalNumber: {
			propertyName: "NormalizedInternationalNumber",
			validationRules: {
				length: {
					errorMessage: "The field NormalizedInternationalNumber must be a string with a minimum length of 9 and a maximum length of 20.",
					parameters: {
						max: 20,
						min: 9,
					},
				},
				required: {
					errorMessage: "The NormalizedInternationalNumber field is required.",
				},
				skipsanitization: {},
			},
		},
		UseVoiceCall: {
			propertyName: "UseVoiceCall",
		},
		Language: {
			propertyName: "Language",
		},
	};
	export let CreateAccountRequest : { [name: string]: IPropertyMetadata } = {
		FirstName: {
			propertyName: "FirstName",
			placeholder: "e.g. James",
			validationRules: {
				length: {
					errorMessage: "First name must be less than 100 characters",
					parameters: {
						max: 100,
					},
				},
				regex: {
					errorMessage: "First names cannot start with special characters",
					parameters: {
						pattern: "^(?![\\s.-]|.*\\s[-.]\\S).*$",
					},
				},
				required: {
					errorMessage: "Please enter first name",
				},
			},
		},
		LastName: {
			propertyName: "LastName",
			placeholder: "e.g. Smith",
			validationRules: {
				length: {
					errorMessage: "Last name must be less than 100 characters",
					parameters: {
						max: 100,
					},
				},
				regex: {
					errorMessage: "Last names cannot start with special characters",
					parameters: {
						pattern: "^(?![\\s.-]|.*\\s[-.]\\S).*$",
					},
				},
				required: {
					errorMessage: "Please enter last name",
				},
			},
		},
		Email: {
			propertyName: "Email",
			placeholder: "e.g. james@smith.com",
			validationRules: {
				email: {
					errorMessage: "This email address is not valid",
				},
				length: {
					errorMessage: "This email address is not valid",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter email address",
				},
			},
		},
		RememberMe: {
			propertyName: "RememberMe",
			validationRules: {
				required: {
					errorMessage: "The RememberMe field is required.",
				},
			},
		},
		Language: {
			propertyName: "Language",
		},
	};
	export let AddCreditCardPaymentMethodRequest : { [name: string]: IPropertyMetadata } = {
		CardNumber: {
			propertyName: "CardNumber",
			displayName: "Card",
			placeholder: "●●●● ●●●● ●●●● ●●●●",
			validationRules: {
				length: {
					errorMessage: "The field Card must be a string with a maximum length of 19.",
					parameters: {
						max: 19,
					},
				},
				required: {
					errorMessage: "The Card field is required.",
				},
				skipsanitization: {},
			},
		},
		CardExpirationMonth: {
			propertyName: "CardExpirationMonth",
			displayName: "Expiry",
			placeholder: "●●",
			validationRules: {
				length: {
					errorMessage: "The field Expiry must be a string with a minimum length of 1 and a maximum length of 2.",
					parameters: {
						max: 2,
						min: 1,
					},
				},
				required: {
					errorMessage: "The Expiry field is required.",
				},
			},
		},
		CardExpirationYear: {
			propertyName: "CardExpirationYear",
			displayName: "/",
			placeholder: "●●",
			validationRules: {
				length: {
					errorMessage: "The field / must be a string with a minimum length of 2 and a maximum length of 2.",
					parameters: {
						max: 2,
						min: 2,
					},
				},
				required: {
					errorMessage: "The / field is required.",
				},
			},
		},
		CardSecurity: {
			propertyName: "CardSecurity",
			displayName: "CVV",
			validationRules: {
				required: {
					errorMessage: "The CVV field is required.",
				},
			},
		},
		ZipCode: {
			propertyName: "ZipCode",
			displayName: "Zip Code",
			placeholder: "●●●●●",
			validationRules: {
				length: {
					errorMessage: "The field Zip Code must be a string with a maximum length of 10.",
					parameters: {
						max: 10,
					},
				},
				required: {
					errorMessage: "The Zip Code field is required.",
				},
			},
		},
	};
	export let AddACHPaymentMethodRequest : { [name: string]: IPropertyMetadata } = {
		RoutingNumber: {
			propertyName: "RoutingNumber",
			displayName: "Routing number",
			validationRules: {
				length: {
					errorMessage: "This routing number is not valid",
					parameters: {
						max: 9,
						min: 9,
					},
				},
				required: {
					errorMessage: "Please enter a 9 digit routing number",
				},
			},
		},
	};
	export let ConfirmACHAccountRequest : { [name: string]: IPropertyMetadata } = {
		RoutingNumber: {
			propertyName: "RoutingNumber",
			displayName: "Routing number",
			placeholder: "●●●●●●●●●",
			validationRules: {
				length: {
					errorMessage: "This routing number is not valid",
					parameters: {
						max: 9,
						min: 9,
					},
				},
				required: {
					errorMessage: "Please enter a 9 digit routing number",
				},
			},
		},
		AccountNumber: {
			propertyName: "AccountNumber",
			displayName: "Account number",
			placeholder: "●●●●●●●●●",
			validationRules: {
				length: {
					errorMessage: "This account number is not valid",
					parameters: {
						max: 17,
						min: 4,
					},
				},
				required: {
					errorMessage: "Please enter a 4-17 digit account number",
				},
				skipsanitization: {},
			},
		},
		AccountType: {
			propertyName: "AccountType",
			displayName: "Account type",
			validationRules: {
				required: {
					errorMessage: "Please choose the account type",
				},
			},
		},
	};
	export let ConfirmPaymentRequest : { [name: string]: IPropertyMetadata } = {
		LineItems: {
			propertyName: "LineItems",
		},
		Amount: {
			propertyName: "Amount",
			placeholder: "0",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
				required: {
					errorMessage: "The Amount field is required.",
				},
			},
		},
		AmountWithFees: {
			propertyName: "AmountWithFees",
			validationRules: {
				number: {
					errorMessage: "The field AmountWithFees must be a number.",
				},
			},
		},
		PayerElectedToCoverFees: {
			propertyName: "PayerElectedToCoverFees",
			validationRules: {
				required: {
					errorMessage: "The PayerElectedToCoverFees field is required.",
				},
			},
		},
		PayerEnabledAnniversaryReminder: {
			propertyName: "PayerEnabledAnniversaryReminder",
			validationRules: {
				required: {
					errorMessage: "The PayerEnabledAnniversaryReminder field is required.",
				},
			},
		},
		PaymentMethod: {
			propertyName: "PaymentMethod",
		},
		ListingId: {
			propertyName: "ListingId",
			validationRules: {
				number: {
					errorMessage: "The field ListingId must be a number.",
				},
				required: {
					errorMessage: "The ListingId field is required.",
				},
			},
		},
		LockVersion: {
			propertyName: "LockVersion",
			validationRules: {
				number: {
					errorMessage: "The field LockVersion must be a number.",
				},
				required: {
					errorMessage: "The LockVersion field is required.",
				},
			},
		},
		Refs: {
			propertyName: "Refs",
		},
		IsRecurring: {
			propertyName: "IsRecurring",
			validationRules: {
				required: {
					errorMessage: "The IsRecurring field is required.",
				},
			},
		},
		IsOneTimeFutureGift: {
			propertyName: "IsOneTimeFutureGift",
			validationRules: {
				required: {
					errorMessage: "The IsOneTimeFutureGift field is required.",
				},
			},
		},
		GiftDate: {
			propertyName: "GiftDate",
			validationRules: {
				date: {
					errorMessage: "The field GiftDate must be a date.",
				},
			},
		},
		RecurringDetails: {
			propertyName: "RecurringDetails",
			get modelMetadata() {
				return ModelMetadata.ConfirmPaymentRecurringDetails;
			},
		},
		UrlParameters: {
			propertyName: "UrlParameters",
			validationRules: {
				length: {
					errorMessage: "The field UrlParameters must be a string with a maximum length of 3000.",
					parameters: {
						max: 3000,
					},
				},
				skipsanitization: {},
			},
		},
		EmailAppContentCardModel: {
			propertyName: "EmailAppContentCardModel",
		},
		DisplayedInWebGivingAppContentCardType: {
			propertyName: "DisplayedInWebGivingAppContentCardType",
		},
		PayerPrefersVoiceCall: {
			propertyName: "PayerPrefersVoiceCall",
			validationRules: {
				required: {
					errorMessage: "The PayerPrefersVoiceCall field is required.",
				},
			},
		},
		ShouldSendWelcomeSMS: {
			propertyName: "ShouldSendWelcomeSMS",
			validationRules: {
				required: {
					errorMessage: "The ShouldSendWelcomeSMS field is required.",
				},
			},
		},
		Notes: {
			propertyName: "Notes",
			validationRules: {
				length: {
					errorMessage: "The field Notes must be a string with a maximum length of 255.",
					parameters: {
						max: 255,
					},
				},
			},
		},
		AdditionalData: {
			propertyName: "AdditionalData",
			validationRules: {
				length: {
					errorMessage: "The field AdditionalData must be a string with a maximum length of 1024.",
					parameters: {
						max: 1024,
					},
				},
			},
		},
		ExternalReference: {
			propertyName: "ExternalReference",
			validationRules: {
				length: {
					errorMessage: "The field ExternalReference must be a string with a maximum length of 60.",
					parameters: {
						max: 60,
					},
				},
			},
		},
		AdditionalHiddenFundKey: {
			propertyName: "AdditionalHiddenFundKey",
			validationRules: {
				length: {
					errorMessage: "The field AdditionalHiddenFundKey must be a string with a maximum length of 50.",
					parameters: {
						max: 50,
					},
				},
			},
		},
		IncludeHiddenFundField: {
			propertyName: "IncludeHiddenFundField",
			validationRules: {
				required: {
					errorMessage: "The IncludeHiddenFundField field is required.",
				},
			},
		},
		TermsAndConditionsVersion: {
			propertyName: "TermsAndConditionsVersion",
			validationRules: {
				length: {
					errorMessage: "The field TermsAndConditionsVersion must be a string with a maximum length of 20.",
					parameters: {
						max: 20,
					},
				},
			},
		},
		InitialUrlParameters: {
			propertyName: "InitialUrlParameters",
			validationRules: {
				length: {
					errorMessage: "The field InitialUrlParameters must be a string with a maximum length of 3000.",
					parameters: {
						max: 3000,
					},
				},
				skipsanitization: {},
			},
		},
		RecurringSuggestionId: {
			propertyName: "RecurringSuggestionId",
			validationRules: {
				number: {
					errorMessage: "The field RecurringSuggestionId must be a number.",
				},
			},
		},
		Language: {
			propertyName: "Language",
		},
		ApplePayInfo: {
			propertyName: "ApplePayInfo",
		},
	};
	export let ResendConfirmPaymentCodeRequest : { [name: string]: IPropertyMetadata } = {
		PaymentToken: {
			propertyName: "PaymentToken",
			validationRules: {
				skipsanitization: {},
			},
		},
		PaymentTokens: {
			propertyName: "PaymentTokens",
			validationRules: {
				skipsanitization: {},
			},
		},
		UseVoiceCall: {
			propertyName: "UseVoiceCall",
		},
		Language: {
			propertyName: "Language",
		},
	};
	export let PollResultRequest : { [name: string]: IPropertyMetadata } = {
		PaymentToken: {
			propertyName: "PaymentToken",
			validationRules: {
				skipsanitization: {},
			},
		},
	};
	export let ConfirmPaymentConfirmCodeRequest : { [name: string]: IPropertyMetadata } = {
		Code: {
			propertyName: "Code",
			displayName: "Confirmation Code",
			placeholder: "e.g. 123456",
			validationRules: {
				length: {
					errorMessage: "Please enter a 6 digit security code",
					parameters: {
						max: 6,
						min: 6,
					},
				},
				required: {
					errorMessage: "Please enter a 6 digit security code",
				},
			},
		},
		PaymentToken: {
			propertyName: "PaymentToken",
			validationRules: {
				skipsanitization: {},
			},
		},
		PaymentTokens: {
			propertyName: "PaymentTokens",
			validationRules: {
				skipsanitization: {},
			},
		},
		RequestToken: {
			propertyName: "RequestToken",
		},
		RememberMe: {
			propertyName: "RememberMe",
			validationRules: {
				required: {
					errorMessage: "The RememberMe field is required.",
				},
			},
		},
		PayerEnabledAnniversaryReminder: {
			propertyName: "PayerEnabledAnniversaryReminder",
			validationRules: {
				required: {
					errorMessage: "The PayerEnabledAnniversaryReminder field is required.",
				},
			},
		},
		ShouldSendWelcomeSms: {
			propertyName: "ShouldSendWelcomeSms",
			validationRules: {
				required: {
					errorMessage: "The ShouldSendWelcomeSms field is required.",
				},
			},
		},
		RecurringSuggestionId: {
			propertyName: "RecurringSuggestionId",
			validationRules: {
				number: {
					errorMessage: "The field RecurringSuggestionId must be a number.",
				},
			},
		},
		EmailAppContentCardModel: {
			propertyName: "EmailAppContentCardModel",
		},
		DisplayedInWebGivingAppContentCardType: {
			propertyName: "DisplayedInWebGivingAppContentCardType",
		},
		Language: {
			propertyName: "Language",
		},
		InitialUrlParameters: {
			propertyName: "InitialUrlParameters",
			validationRules: {
				length: {
					errorMessage: "The field InitialUrlParameters must be a string with a maximum length of 3000.",
					parameters: {
						max: 3000,
					},
				},
				skipsanitization: {},
			},
		},
		ApplePayInfo: {
			propertyName: "ApplePayInfo",
		},
	};
	export let PageStatsRequest : { [name: string]: IPropertyMetadata } = {
		PageName: {
			propertyName: "PageName",
		},
		PaymentIdentifier: {
			propertyName: "PaymentIdentifier",
			validationRules: {
				skipsanitization: {},
			},
		},
		BrandingElements: {
			propertyName: "BrandingElements",
		},
		AdditionalData: {
			propertyName: "AdditionalData",
		},
		Timestamp: {
			propertyName: "Timestamp",
			validationRules: {
				date: {
					errorMessage: "The field Timestamp must be a date.",
				},
				required: {
					errorMessage: "The Timestamp field is required.",
				},
			},
		},
	};
	export let RecurringSuggestionQuery : { [name: string]: IPropertyMetadata } = {
		FundKey: {
			propertyName: "FundKey",
		},
		SignedPayerId: {
			propertyName: "SignedPayerId",
		},
		Amount: {
			propertyName: "Amount",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
				required: {
					errorMessage: "The Amount field is required.",
				},
			},
		},
	};
	export let RecurringSuggestionModel : { [name: string]: IPropertyMetadata } = {
		FundKey: {
			propertyName: "FundKey",
		},
		SignedPayerId: {
			propertyName: "SignedPayerId",
		},
		Frequency: {
			propertyName: "Frequency",
		},
		RecurrenceDate: {
			propertyName: "RecurrenceDate",
			validationRules: {
				date: {
					errorMessage: "The field RecurrenceDate must be a date.",
				},
			},
		},
		Amount: {
			propertyName: "Amount",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
			},
		},
	};
	export let UpdateFastModeEnabledRequest : { [name: string]: IPropertyMetadata } = {
		FastModeEnabled: {
			propertyName: "FastModeEnabled",
			validationRules: {
				required: {
					errorMessage: "The FastModeEnabled field is required.",
				},
			},
		},
		GiftAmount: {
			propertyName: "GiftAmount",
			validationRules: {
				number: {
					errorMessage: "The field GiftAmount must be a number.",
				},
				required: {
					errorMessage: "The GiftAmount field is required.",
				},
			},
		},
	};
	export let ApplePayValidateMerchantRequest : { [name: string]: IPropertyMetadata } = {
		Uri: {
			propertyName: "Uri",
			validationRules: {
				required: {
					errorMessage: "The Uri field is required.",
				},
			},
		},
	};
	export let WebGivingLogInfoRequest : { [name: string]: IPropertyMetadata } = {
		Type: {
			propertyName: "Type",
			validationRules: {
				required: {
					errorMessage: "The Type field is required.",
				},
			},
		},
		Translations: {
			propertyName: "Translations",
		},
		ApplePaySDKInfo: {
			propertyName: "ApplePaySDKInfo",
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export type CardBrandConfig = {
	key: string;
	shortName: string;
	friendlyName: string;
	shouldLuhn: boolean;
	pattern: RegExp;
	cvvLength: number;
	cvvMask: any[];
	cvvRegex: RegExp;
	cardNumberMask: any[];
	cardNumberRegex: RegExp;
};
export const CardBrandData: CardBrandConfig[] = [
{
	key: "3",
	shortName: "Amex",
	friendlyName: "American Express",
	shouldLuhn: true,
	pattern: /^3[47]/,
	cvvLength: 4,
	cvvMask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
	cvvRegex: /^[0-9]{4}$/,
	cardNumberMask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
	cardNumberRegex: /^[0-9]{4} [0-9]{6} [0-9]{5}$/,
},
{
	key: "4",
	shortName: "Discover",
	friendlyName: "Discover",
	shouldLuhn: true,
	pattern: /^(6011|65|64[4-9]|622)/,
	cvvLength: 3,
	cvvMask: [/[0-9]/, /[0-9]/, /[0-9]/],
	cvvRegex: /^[0-9]{3}$/,
	cardNumberMask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
	cardNumberRegex: /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
},
{
	key: "1",
	shortName: "MasterCard",
	friendlyName: "MasterCard",
	shouldLuhn: true,
	pattern: /^(5[0-5]|2[2-7])/,
	cvvLength: 3,
	cvvMask: [/[0-9]/, /[0-9]/, /[0-9]/],
	cvvRegex: /^[0-9]{3}$/,
	cardNumberMask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
	cardNumberRegex: /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
},
{
	key: "2",
	shortName: "VISA",
	friendlyName: "Visa",
	shouldLuhn: true,
	pattern: /^4/,
	cvvLength: 3,
	cvvMask: [/[0-9]/, /[0-9]/, /[0-9]/],
	cvvRegex: /^[0-9]{3}$/,
	cardNumberMask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
	cardNumberRegex: /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
}];
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export type CountryConfig= {
	displayName: string;
	//two-letter display code. Usually the CCA2 except when we override it, i.e. GB=>UK
	displayCode2: string;
	countryCode: Country;
	mobilePlaceholder: string;
	callingCode: string;
};
export const CountryData: CountryConfig[] = [
{
	displayName: "Australia",
	displayCode2: "AU",
	countryCode: 1,
	mobilePlaceholder: "0412 345 678",
	callingCode: "+61",
},
{
	displayName: "Belgium",
	displayCode2: "BE",
	countryCode: 56,
	mobilePlaceholder: "0470 12 34 56",
	callingCode: "+32",
},
{
	displayName: "Canada",
	displayCode2: "CA",
	countryCode: 124,
	mobilePlaceholder: "(201) 555-5555",
	callingCode: "+1",
},
{
	displayName: "Switzerland",
	displayCode2: "CH",
	countryCode: 756,
	mobilePlaceholder: "078 123 45 67",
	callingCode: "+41",
},
{
	displayName: "Germany",
	displayCode2: "DE",
	countryCode: 276,
	mobilePlaceholder: "0162 1234567",
	callingCode: "+49",
},
{
	displayName: "Denmark",
	displayCode2: "DK",
	countryCode: 208,
	mobilePlaceholder: "20-12-34-56",
	callingCode: "+45",
},
{
	displayName: "Finland",
	displayCode2: "FI",
	countryCode: 246,
	mobilePlaceholder: "045 71234567",
	callingCode: "+358",
},
{
	displayName: "United Kingdom",
	displayCode2: "UK",
	countryCode: 826,
	mobilePlaceholder: "07900 123456",
	callingCode: "+44",
},
{
	displayName: "Guatemala",
	displayCode2: "GT",
	countryCode: 320,
	mobilePlaceholder: "5123-4567",
	callingCode: "+502",
},
{
	displayName: "Iceland",
	displayCode2: "IS",
	countryCode: 352,
	mobilePlaceholder: "678 1234",
	callingCode: "+354",
},
{
	displayName: "Latvia",
	displayCode2: "LV",
	countryCode: 428,
	mobilePlaceholder: "23 123 456",
	callingCode: "+371",
},
{
	displayName: "Mexico",
	displayCode2: "MX",
	countryCode: 484,
	mobilePlaceholder: "(01) 55 5555 5555",
	callingCode: "+52",
},
{
	displayName: "Norway",
	displayCode2: "NO",
	countryCode: 578,
	mobilePlaceholder: "456 12 345",
	callingCode: "+47",
},
{
	displayName: "New Zealand",
	displayCode2: "NZ",
	countryCode: 0,
	mobilePlaceholder: "(021) 123-4567",
	callingCode: "+64",
},
{
	displayName: "Puerto Rico",
	displayCode2: "PR",
	countryCode: 630,
	mobilePlaceholder: "(787) 555-5555",
	callingCode: "+1",
},
{
	displayName: "Sweden",
	displayCode2: "SE",
	countryCode: 752,
	mobilePlaceholder: "070-123 45 67",
	callingCode: "+46",
},
{
	displayName: "Singapore",
	displayCode2: "SG",
	countryCode: 702,
	mobilePlaceholder: "8551-2345",
	callingCode: "+65",
},
{
	displayName: "United States of America",
	displayCode2: "US",
	countryCode: 2,
	mobilePlaceholder: "(201) 555-5555",
	callingCode: "+1",
},
{
	displayName: "South Africa",
	displayCode2: "ZA",
	countryCode: 710,
	mobilePlaceholder: "023 456 7890",
	callingCode: "+27",
}];
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export const LanguageOptions: Record<string, Language> = {
	en: {"Name":"English","TwoLetterIsoCode":"en"},
	es: {"Name":"Spanish","TwoLetterIsoCode":"es"}};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
